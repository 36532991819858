import React from 'react'
import { ThemeProvider } from '@leshen/ui'
import GlobalStyles from './CustomStyles'
import siteTheme from 'gatsby-theme-centurylink/theme'

const Wrapper = ({ children }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default Wrapper
